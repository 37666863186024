import React from "react";
import cake1 from "../assets/SignatureCakes/cake1.png";
import cake2 from "../assets/SignatureCakes/cake2.jpg";
import cake3 from "../assets/SignatureCakes/cake3.jpg";
import cake4 from "../assets/SignatureCakes/cake4.jpg";
import cake5 from "../assets/SignatureCakes/cake5.jpg";
import cake6 from "../assets/SignatureCakes/cake6.jpg";
import cake7 from "../assets/SignatureCakes/cake7.jpg";
import cake8 from "../assets/SignatureCakes/cake8.jpg";
import cake9 from "../assets/SignatureCakes/cake9.jpg";
import cake10 from "../assets/SignatureCakes/cake10.jpg";
import cake11 from "../assets/SignatureCakes/cake11.jpg";
import nonveg from "../assets/image/nonveg.png";
import cloud from "../assets/image/cloud2.png";
import { Link } from "react-router-dom";


const SignatureCakesPage = () => {

    const data = [
        { id: 1, image: cake1, title: "Black Forest Ultima", time: "Min <br /> 5 Hr." },
        { id: 2, image: cake2, title: "Nutella", time: "Min <br /> 2 Hr." },
        { id: 3, image: cake3, title: "American Fudge", time: "Min <br /> 3 Hr." },
        { id: 4, image: cake4, title: "Ras Malai cake", time: "Min <br /> 2 Hr." },
        { id: 5, image: cake5, title: "Germen Chocolate Cake", time: "Min <br /> 1 Hr." },
        { id: 6, image: cake6, title: "Rocher Plus", time: "Min <br /> 2 Hr." },
        { id: 7, image: cake7, title: "Black Forest Ultima Cake", time: "Min <br /> 1 Hr." },
        { id: 8, image: cake8, title: "Apple Cinnamon Cake", time: "Min <br /> 2 Hr." },
        { id: 9, image: cake9, title: "Dutch Tuffle Cake", time: "Min <br /> 5 Hr." },
        { id: 10, image: cake10, title: "Rocher Plus", time: "Min <br /> 2 Hr." },
        { id: 11, image: cake11, title: "Swiss Chocolate Cake", time: "Min <br /> 3 Hr." },
    ];

    return (
        <>
            <div className="page-banner">
                <h1 className="breadcrumb-title">Signature Cakes</h1>
            </div>

            <div className="container">
            <div className="section mx-lg-5 mx-2">
                <div className="row">
                    {
                        data.map((item) => (
                            <div className="col-lg-3 col-md-4 col-6 mb-3">
                                <Link to="https://www.zomato.com/surat/the-centre-court-city-light/order"
                                    target="_blank" className="text-decoration-none">
                                    <div className="image-cakes" key={item.id}>
                                        <img src={item.image} alt="Cake" className="main-img img-fluid " />
                                        <div className="img-cakes-text d-flex justify-content-between align-items-center mt-3 pb-2 ">
                                            <p className="mb-0">{item.title}</p>
                                            <img src={nonveg} alt="Non-Veg Icon" className="nonveg-img img-fluid d-lg-block d-md-block d-none" style={{ width: '40px', height: '20px' }} />
                                        </div>
                                        <div className="cloud-container">
                                            <img src={cloud} alt="Cloud Decoration" className="cloud-img " />
                                            <small dangerouslySetInnerHTML={{ __html: item.time }}></small>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        ))}
                </div>
            </div>
            </div>
        </>
    );
};

export default SignatureCakesPage;
