import { Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from "./Pages/Home";
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import ImageCakesPage from './Pages/ImageCakesPage';
import ScrollToTopButton from './components/ScrollToTopButton';
import HeartCakesPage from './Pages/HeartCakesPage';
import SignatureCakesPage from './Pages/SignatureCakesPage';
import SpecialCakesPages from './Pages/SpecialCakesPage';
import Lookbook from './Pages/Lookbook';
import AnniversaryCakesPage from './Pages/AnniversaryCakesPage';
import AboutUs from './Pages/AboutUs';
import ContactUs from './Pages/ContactUs';
// import PrivacyPolicy from './Pages/PrivacyPolicy';
// import TermCondition from './Pages/TermCondition';
import Scroll from './components/Scroll';
// import RefundCancellation from './Pages/RefundCancellation';
import AllCakesPage from './Pages/AllCakesPage';
// import Loader from './components/Loader';
// import { useState, useEffect } from 'react';

function App() {
  
  // const [loading, setLoading] = useState(true);

  
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setLoading(false); 
  //   }, 1000);

  //   return () => clearTimeout(timer); 
  // }, []);

  return (
    <>
      {/* {loading && <Loader />}  */}
      <ScrollToTopButton />
      <Scroll />
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<Navigate to="/" replace />} />
        <Route path="/image-cakes" element={<ImageCakesPage />} />
        <Route path="/heart-cakes" element={<HeartCakesPage />} />
        <Route path="/signature-cakes" element={<SignatureCakesPage />} />
        <Route path="/special-cakes" element={<SpecialCakesPages />} />
        <Route path="/lookbook" element={<Lookbook />} />
        <Route path="/anniversary-cakes" element={<AnniversaryCakesPage />} />
        <Route path="/all-cakes" element={<AllCakesPage />} />
        <Route path='/about-us' element={<AboutUs />} />
        <Route path="/contact-us" element={<ContactUs />} />
        {/* <Route path='/privacy-policies' element={<PrivacyPolicy />} /> */}
        {/* <Route path='/term-conditions' element={<TermCondition />} /> */}
        {/* <Route path='/refund-cancellation' element={<RefundCancellation />} /> */}
      </Routes>
      <Footer />
    </>
  );
}

export default App;
