import React from "react";
import cake1 from "../assets/cakes/cake1.png";
import cake2 from "../assets/cakes/cake5.jpeg";
import cake3 from "../assets/cakes/cake7.jpeg";

import nonveg from "../assets/image/nonveg.png";
import cloud from "../assets/image/cloud2.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";


const ImageCakes = () => {

    const settings = {
        dots: true,
        arrows: false,
        infinite: false,
        autoplay: false,
        autoplaySpeed: 2000,
        slidesToShow: 5,
        slidesToScroll: 5,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 425,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
        ],
    };

    const data = [
        {
            id: 1,
            image: cake1,
            title: "Edible Image Cake 001",
            time: "Min <br /> 5 Hr."
        },
        {
            id: 2,
            image: cake3,
            title: "Edible Photo Cake",
            time: "Min <br /> 5 Hr."
        },
        {
            id: 3,
            image: cake2,
            title: "Valentine Cupid Cake",
            time: "Min <br /> 5 Hr."
        },
       
    ]

    const preventDrag = (e) => {
        e.preventDefault();
    };


    return (
        <div className="container">
            <div className="section">
                <h1 className="title">Image Cakes</h1>
                <div className="carousel-container">
                    <Slider {...settings}>
                        {
                            data.map((item) => (
                                <Link to="https://www.zomato.com/surat/the-centre-court-city-light/order" 
                                target="_blank"
                                className="text-decoration-none"
                                    onMouseDown={preventDrag}
                                >
                                    <div className="image-cakes" key={item.id}>
                                        <img src={item.image} alt="Cake" className="main-img img-fluid " />
                                        <div className="img-cakes-text d-flex justify-content-between align-items-center mt-3 py-2 ">
                                            <p className="mb-0">{item.title}</p>
                                            <img src={nonveg} alt="Non-Veg Icon" className="nonveg-img img-fluid" style={{ width: '35px', height: '17px' }} />
                                        </div>
                                        <div className="cloud-container">
                                            <img src={cloud} alt="Cloud Decoration" className="cloud-img " />
                                            <small dangerouslySetInnerHTML={{ __html: item.time }}></small>
                                        </div>
                                    </div>
                                </Link>
                            ))}
                    </Slider>
                </div>
            </div>
        </div>
    );
};

export default ImageCakes;
