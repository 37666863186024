import React, { useState, useEffect } from "react";
import { MdKeyboardDoubleArrowUp } from "react-icons/md";


const ScrollToTopButton = () => {
    const [isVisible, setIsVisible] = useState(false);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    const handleScroll = () => {
        const scrollTop = window.pageYOffset;
        if (scrollTop > 600) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <button
            className={`scroll-top-btn ${isVisible ? "d-block" : "d-none"}`}
            onClick={scrollToTop}
        >
            <MdKeyboardDoubleArrowUp size={24} />
        </button>
    );
};

export default ScrollToTopButton;
