import React, { useState } from "react";
import "../styles/Navbar.css";
import logo1 from "../assets/logo/logoDesktop.png";
import logo2 from "../assets/logo/logoMobile.png";
import { Link } from "react-router-dom";

const Navbar = () => {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const closeMenu = () => {
        setMenuOpen(false);
    };

    return (
        <>
            {/* Navbar for Desktop */}
            <div className="navMenuDesktop">
                <div className="container navContainer">
                    <div className="logo">
                        <Link to="/">
                            <img src={logo1} alt="logo" />
                        </Link>
                    </div>
                    <div className="menuItems">
                        <ul>
                            <li>
                                <Link to="/image-cakes">Image Cakes</Link>
                            </li>
                            <li>
                                <Link to="/heart-cakes">Heart Cakes</Link>
                            </li>
                            <li>
                                <Link to="/signature-cakes">Signature Cakes</Link>
                            </li>
                            <li>
                                <Link to="/special-cakes">Special Cakes</Link>
                            </li>
                            <li>
                                <Link to="/anniversary-cakes">Anniversary Cakes</Link>
                            </li>
                            <li>
                                <Link to="/all-cakes">All Cakes</Link>
                            </li>
                            <li>
                                <Link to="/lookbook">Lookbook</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            {/* Navbar for Mobile/Tablet */}
            <div className="navMenuMobile">
                <div className="container navContainer px-0">
                    <div className="hamburger" onClick={toggleMenu}>
                        {/* <span className="lines">&#9776;</span> */}
                        <span className="lines"></span>
                        <span className="lines"></span>
                        <span className="lines"></span>
                    </div>
                    <div className="logo">
                        <Link to="/">
                            <img src={logo2} alt="logo" />
                        </Link>
                    </div>

                    <div className={`menuItems ${menuOpen ? "active" : ""}`}>
                        <div className="mobile-menu-div">
                            <span>Menu</span>
                        </div>
                        <ul>
                            <li>
                                <Link to="/image-cakes" onClick={closeMenu}>Image Cakes</Link>
                            </li>
                            <hr className="my-2" />
                            <li>
                                <Link to="/heart-cakes" onClick={closeMenu}>Heart Cakes</Link>
                            </li>
                            <hr className="my-2" />
                            <li>
                                <Link to="/signature-cakes" onClick={closeMenu}>Signature Cakes</Link>
                            </li>
                            <hr className="my-2" />
                            <li>
                                <Link to="/special-cakes" onClick={closeMenu}>Special Cakes</Link>
                            </li>
                            <hr className="my-2" />
                            <li>
                                <Link to="/anniversary-cakes" onClick={closeMenu}>Anniversary Cakes</Link>
                            </li>
                            <hr className="my-2" />
                            <li>
                                <Link to="/all-cakes" onClick={closeMenu}>All Cakes</Link>
                            </li>
                            <hr className="my-2" />
                            <li>
                                <Link to="/lookbook" onClick={closeMenu}>Lookbook</Link>
                            </li>
                            <hr className="my-2" />
                        </ul>
                    </div>

                    {/* Overlay for closing menu on outside click */}
                    {menuOpen && <div className="menu-overlay" onClick={closeMenu}></div>}
                </div>
            </div>

            <div className="container">
                <div className="magic-dis-txt">
                    <p>Get a flat 10% discount on all products.!!</p>
                </div>
            </div>
        </>
    );
};

export default Navbar;
