import React from "react";
import { Link } from "react-router-dom";
import { GrLocation } from "react-icons/gr";
import { LuPhoneCall } from "react-icons/lu";
import { MdOutlineMail } from "react-icons/md";
import { MdAccessTime } from "react-icons/md";
import { FaFacebookF } from "react-icons/fa";
import { FiTwitter } from "react-icons/fi";
import { FaInstagram } from "react-icons/fa";
import { FiYoutube } from "react-icons/fi";
import { FiLinkedin } from "react-icons/fi";

const ContactUs = () => {
    return <>
        <div className="page-banner">
            <h1 className="breadcrumb-title">Contact Us</h1>
        </div>
        <div className="container-fluid contact-us section">
            <div className="row">
                <div className="col-lg-6">
                    <h3>
                        Contact Us
                    </h3>
                    <div className="mb-4">
                        <div className="c-address">
                            <i className="icon-location"><GrLocation size={30} /></i>
                            <p>
                                <strong>The Centre Court</strong><br />
                                Near Ambikaniketan Bus- Stand,<br />
                                Parle Point, Athwalines,<br />
                                Surat, Gujarat, India.</p>
                        </div>

                        <div className="c-contact">
                            <i className="icon-phone"><LuPhoneCall size={30} /></i>
                            <p>
                                <Link>+91 9664675533</Link> / <Link>+91 8800441320</Link>
                            </p>
                        </div>
                        <div className="c-email">
                            <i className="icon-email"><MdOutlineMail size={30} />
                            </i>
                            <p>
                                <Link>thecentrecourtcakes@gmail.com</Link>
                            </p>
                        </div>
                        <div className="c-time">
                            <i className="icon-time"> <MdAccessTime size={30} /></i>
                            <p>
                                7 Days a week from 10-00 am to 11-00 pm
                            </p>
                        </div>
                    </div>
                    <div className="social-media">
                        <ul className="sm-ul">
                            <li className='sm-li'>
                                <Link to="https://www.facebook.com/thecentrecourtcakes" target="_blank" className="">
                                    <FaFacebookF />
                                </Link>
                            </li>
                            <li className='sm-li'>
                                <Link to="" target="_blank" className="">
                                    <FiTwitter />
                                </Link>
                            </li>
                            <li className='sm-li'>
                                <Link to="https://www.instagram.com/thecentrecourt_bakery" target="_blank" className="">
                                    <FaInstagram />
                                </Link>
                            </li>
                            <li className='sm-li'>
                                <Link to="#" target="_blank" className="">
                                    <FiYoutube />
                                </Link>
                            </li>
                            <li className='sm-li'>
                                <Link to="#" target="_blank" className="">
                                    <FiLinkedin />
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </>;
};

export default ContactUs;
