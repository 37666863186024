// import React from "react";
// import cake1 from "../assets/heartCakes/cake1.jpg";
// import cake2 from "../assets/heartCakes/cake2.jpg";
// import cake3 from "../assets/heartCakes/cake3.jpg";
// import cake4 from "../assets/heartCakes/cake4.jpg";
// import cake5 from "../assets/heartCakes/cake5.jpg";

// import nonveg from "../assets/image/nonveg.png";
// import cloud from "../assets/image/cloud2.png";
// import Carousel from "react-multi-carousel";
// import "react-multi-carousel/lib/styles.css";
// import { Link } from "react-router-dom";

// const HeartCakes = () => {

//     const responsive = {
//         desktop: {
//             breakpoint: { max: 3000, min: 1024 },
//             items: 5,
//         },
//         tablet: {
//             breakpoint: { max: 1024, min: 768 },
//             items: 2,
//         },
//         mobile: {
//             breakpoint: { max: 767, min: 320 },
//             items: 1,
//         },
//     };


//     const data = [
//         { id: 1, image: cake1, title: "Chocolate Couple Cake", time: "Min <br /> 5 Hr." },
//         { id: 2, image: cake2, title: "My Heart Beats Cake for U", time: "Min <br /> 12 Hr." },
//         { id: 3, image: cake3, title: "Cake of Love", time: "Min <br /> 3 Hr." },
//         { id: 4, image: cake4, title: "Sweet Heart Cake", time: "Min <br /> 5 Hr." },
//         { id: 5, image: cake5, title: "Rocher", time: "Min <br /> 11 Hr." },
//     ];

//     return (
//         <div className="section">
//             <h1 className="title">Heart Cakes</h1>
//             <div className="container">
//                 <div className="carousel-container">
//                     <Carousel
//                         responsive={responsive}
//                         autoPlay={true}
//                         arrows={false}
//                         swipeable={true}
//                         draggable={true}
//                         showDots={false}
//                         infinite={true}
//                     >
//                         {data.map((item) => (
//                             <Link to="#" className="text-decoration-none" key={item.id}>
//                                 <div className="carousel image-cakes">
//                                     <img src={item.image} alt={`Cake: ${item.title}`} className="main-img img-fluid" />
//                                     <div className="img-cakes-text d-flex justify-content-between align-items-center mt-3 py-2">
//                                         <p className="mb-0">{item.title}</p>
//                                         <img src={nonveg} alt="Non-Veg Icon" className="nonveg-img img-fluid" style={{ width: '35px', height: '17px' }} />
//                                     </div>
//                                     <div className="cloud-container">
//                                         <img src={cloud} alt="Cloud Decoration" className="cloud-img" />
//                                         <small dangerouslySetInnerHTML={{ __html: item.time }}></small>
//                                     </div>
//                                 </div>
//                             </Link>
//                         ))}
//                     </Carousel>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default HeartCakes;

import React from "react";
import cake1 from "../assets/heartCakes/cake1.jpg";
import cake2 from "../assets/heartCakes/cake2.jpg";
import cake3 from "../assets/heartCakes/cake3.jpg";
import cake4 from "../assets/heartCakes/cake4.jpg";
import cake5 from "../assets/heartCakes/cake5.jpg";

import nonveg from "../assets/image/nonveg.png";
import cloud from "../assets/image/cloud2.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

const HeartCakes = () => {
    const settings = {
        dots: true,
        arrows: false,
        infinite: false,
        autoplay: false,
        autoplaySpeed: 2000,
        slidesToShow: 5,
        slidesToScroll: 5,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 425,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
        ],
    };

    const data = [
        { id: 1, image: cake1, title: "Chocolate Couple Cake", time: "Min <br /> 5 Hr." },
        { id: 2, image: cake2, title: "My Heart Beats Cake for U", time: "Min <br /> 12 Hr." },
        { id: 3, image: cake3, title: "Cake of Love", time: "Min <br /> 3 Hr." },
        { id: 4, image: cake4, title: "Sweet Heart Cake", time: "Min <br /> 5 Hr." },
        { id: 5, image: cake5, title: "Rocher", time: "Min <br /> 11 Hr." },
        // { id: 6, image: cake3, title: "Rocher", time: "Min <br /> 11 Hr." },
    ];

    const preventDrag = (e) => {
        e.preventDefault();
    };

    return (
        <div className="section">
            <h1 className="title">Heart Cakes</h1>
            <div className="container">
                <div className="carousel-container">
                    <Slider {...settings}>
                        {data.map((item) => (
                            <div className="carousel image-cakes" key={item.id}>
                                {/* Use onMouseDown to prevent dragging when clicking the Link */}
                                <Link to="https://www.zomato.com/surat/the-centre-court-city-light/order"
                                    target="_blank"
                                    className="text-decoration-none"
                                    onMouseDown={preventDrag}
                                >
                                    <div className="carousel-content">
                                        <img
                                            src={item.image}
                                            alt={`Cake: ${item.title}`}
                                            className="main-img img-fluid"
                                        />
                                        <div className="img-cakes-text d-flex justify-content-between align-items-center mt-3 py-2">
                                            <p className="mb-0">{item.title}</p>
                                            <img
                                                src={nonveg}
                                                alt="Non-Veg Icon"
                                                className="nonveg-img img-fluid"
                                                style={{ width: '35px', height: '17px' }}
                                            />
                                        </div>
                                        <div className="cloud-container">
                                            <img
                                                src={cloud}
                                                alt="Cloud Decoration"
                                                className="cloud-img"
                                            />
                                            <small dangerouslySetInnerHTML={{ __html: item.time }}></small>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </div>
    );
};

export default HeartCakes;


