import React from "react";
import cake1 from "../assets/SpecialCakes/cake1.jpg";
import cake2 from "../assets/SpecialCakes/cake2.jpg";
import cake3 from "../assets/SpecialCakes/cake3.jpg";
import cake4 from "../assets/SpecialCakes/cake4.jpg";
import cake5 from "../assets/SpecialCakes/cake5.jpg";
import cake6 from "../assets/SpecialCakes/cake6.jpg";
import cake7 from "../assets/SpecialCakes/cake7.jpg";
import cake8 from "../assets/SpecialCakes/cake8.jpg";


import nonveg from "../assets/image/nonveg.png";
import cloud from "../assets/image/cloud2.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

const SpecialCakes = () => {

    const settings = {
        dots: true,
        arrows: false,
        infinite: false,
        autoplay: false,
        autoplaySpeed: 2000,
        slidesToShow: 5,
        slidesToScroll: 5,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 425,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
        ],
    };

    const data = [
        { id: 1, image: cake1, title: "Macaroon Cake", time: "Min <br /> 5 Hr." },
        { id: 2, image: cake2, title: "Melting Chocolate Cake", time: "Min <br /> 12 Hr." },
        { id: 3, image: cake3, title: "Teddy Luv Cake", time: "Min <br /> 13 Hr." },
        { id: 4, image: cake4, title: "Dance The night away Cake", time: "Min <br /> 11 Hr." },
        { id: 5, image: cake5, title: "On The Knees For U Cake", time: "Min <br /> 8 Hr." },
        { id: 6, image: cake6, title: "Teddy In Love Cake", time: "Min <br /> 6 Hr." },
        { id: 7, image: cake7, title: "Silhouette Cake", time: "Min <br /> 5 Hr." },
        { id: 8, image: cake8, title: "Giant Cup Cake", time: "Min <br /> 12 Hr." },
    ];

    const preventDrag = (e) => {
        e.preventDefault();
    };

    return (
        <div className="section">
            <h1 className="title">Special Cakes</h1>
            <div className="container">
                <div className="carousel-container">
                    <Slider {...settings}>
                        {data.map((item) => (
                            <Link to="https://www.zomato.com/surat/the-centre-court-city-light/order"
                                target="_blank"
                                className="text-decoration-none" key={item.id} onMouseDown={preventDrag}>
                                <div className="image-cakes">
                                    <img src={item.image} alt={`Special Cake: ${item.title}`} className="main-img img-fluid" />
                                    <div className="img-cakes-text d-flex justify-content-between align-items-center mt-3 py-2">
                                        <p className="mb-0">{item.title}</p>
                                        <img src={nonveg} alt="Non-Veg Icon" className="nonveg-img img-fluid" style={{ width: '35px', height: '17px' }} />
                                    </div>
                                    <div className="cloud-container">
                                        <img src={cloud} alt="Cloud Decoration" className="cloud-img" />
                                        <small dangerouslySetInnerHTML={{ __html: item.time }}></small>
                                    </div>
                                </div>
                            </Link>
                        ))}
                    </Slider>
                </div>
            </div>
        </div>
    );
};

export default SpecialCakes;
