import React from "react";
import cake1 from "../assets/cakes/cake3.jpeg";
import cake2 from "../assets/cakes/cake4.jpeg";
import cake3 from "../assets/cakes/cake6.jpeg";
import cake4 from "../assets/cakes/cake8.jpeg";
import { Link } from "react-router-dom";

const CakeShowCase = () => {
    return (
        <div className="container">
            <div className="section">
                <div className="row gallery-container">
                    <div className="col-lg-6 col-md-6 gallery-item">

                        <Link to="#">
                            <div className="image-overlay">
                                <img src={cake1} alt="Fruit Cakes" className="img-fluid" />
                                <div className="overlay-text1">Fruit <br /> Cakes</div>
                            </div>
                        </Link>
                    </div>
                    <div className=" col-lg-6 col-md-6">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 gallery-item">
                                <Link to="#">
                                    <div className="image-overlay">
                                        <img src={cake2} alt="Creamy Tart" className="img-fluid" />
                                        <div className="overlay-text">Creamy Tart</div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-lg-6 col-md-6 gallery-item">
                                <Link to="#">
                                    <div className="image-overlay">
                                        <img src={cake3} alt="Chocolate" className="img-fluid" />
                                        <div className="overlay-text">Chocolate</div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 gallery-item">
                                <Link to="#">
                                    <div className="image-overlay">
                                        <img src={cake4} alt="Berry Cakes" className="img-fluid" />
                                        <div className="overlay-text">Berry Cakes</div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CakeShowCase;
