import React from "react";
import { Link } from "react-router-dom";

const Flavours = () => {
    return <>
        <div className="container">
            <div className="section mx-3">
                <h1 className="title">Most Loved Flavours</h1>
                <div className="row mb-2">
                    <div className="col-lg-2 all-cake-links-title">Photo Cakes-</div>
                    <div className="col-lg-10  all-cake-links">
                        <Link
                            to="https://www.zomato.com/surat/the-centre-court-city-light/order"
                            target="_blank"
                        > German Chocolate Cake</Link> /
                        <Link
                            to="https://www.zomato.com/surat/the-centre-court-city-light/order"
                            target="_blank"
                        > German Chocolate Cake</Link> /
                        <Link
                            to="https://www.zomato.com/surat/the-centre-court-city-light/order"
                            target="_blank"
                        > German Chocolate Cake</Link> /
                        <Link
                            to="https://www.zomato.com/surat/the-centre-court-city-light/order"
                            target="_blank"
                        > German Chocolate Cake</Link> /
                        <Link
                            to="https://www.zomato.com/surat/the-centre-court-city-light/order"
                            target="_blank"
                        > German Chocolate Cake</Link> 
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col-lg-2 all-cake-links-title">Heart-Shaped Cakes -</div>
                    <div className="col-lg-10  all-cake-links">
                        <Link
                            to="https://www.zomato.com/surat/the-centre-court-city-light/order"
                            target="_blank"
                        > Apple Cinnamon Cake</Link> /
                        <Link
                            to="https://www.zomato.com/surat/the-centre-court-city-light/order"
                            target="_blank"
                        > Apple Cinnamon Cake</Link> /
                        <Link
                            to="https://www.zomato.com/surat/the-centre-court-city-light/order"
                            target="_blank"
                        > Apple Cinnamon Cake</Link> /
                        <Link
                            to="https://www.zomato.com/surat/the-centre-court-city-light/order"
                            target="_blank"
                        > Apple Cinnamon Cake</Link> /
                        <Link
                            to="https://www.zomato.com/surat/the-centre-court-city-light/order"
                            target="_blank"
                        > Apple Cinnamon Cake</Link>
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col-lg-2 all-cake-links-title">Signature Cakes -</div>
                    <div className="col-lg-10  all-cake-links">
                        <Link
                            to="https://www.zomato.com/surat/the-centre-court-city-light/order"
                            target="_blank"
                        > Black Forest Ultima Cake</Link> /
                        <Link
                            to="https://www.zomato.com/surat/the-centre-court-city-light/order"
                            target="_blank"
                        > Black Forest Ultima Cake</Link> /
                        <Link
                            to="https://www.zomato.com/surat/the-centre-court-city-light/order"
                            target="_blank"
                        > Black Forest Ultima Cake</Link> /
                        <Link
                            to="https://www.zomato.com/surat/the-centre-court-city-light/order"
                            target="_blank"
                        > Black Forest Ultima Cake</Link> /
                        <Link
                            to="https://www.zomato.com/surat/the-centre-court-city-light/order"
                            target="_blank"
                        > Black Forest Ultima Cake</Link> 
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col-lg-2 all-cake-links-title">Special Cakes -</div>
                    <div className="col-lg-10  all-cake-links">
                        <Link
                            to="https://www.zomato.com/surat/the-centre-court-city-light/order"
                            target="_blank"
                        > Bounty Cake</Link> /
                        <Link
                            to="https://www.zomato.com/surat/the-centre-court-city-light/order"
                            target="_blank"
                        > Bounty Cake</Link> /
                        <Link
                            to="https://www.zomato.com/surat/the-centre-court-city-light/order"
                            target="_blank"
                        > Bounty Cake</Link> /
                        <Link
                            to="https://www.zomato.com/surat/the-centre-court-city-light/order"
                            target="_blank"
                        > Bounty Cake</Link> /
                        <Link
                            to="https://www.zomato.com/surat/the-centre-court-city-light/order"
                            target="_blank"
                        > Bounty Cake</Link> /
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col-lg-2 all-cake-links-title">Anniversary Cakes -</div>
                    <div className="col-lg-10  all-cake-links">
                        <Link
                            to="https://www.zomato.com/surat/the-centre-court-city-light/order"
                            target="_blank"
                        > Blueberry Cake</Link> /
                        <Link
                            to="https://www.zomato.com/surat/the-centre-court-city-light/order"
                            target="_blank"
                        > Blueberry Cake</Link> /
                        <Link
                            to="https://www.zomato.com/surat/the-centre-court-city-light/order"
                            target="_blank"
                        > Blueberry Cake</Link> /
                        <Link
                            to="https://www.zomato.com/surat/the-centre-court-city-light/order"
                            target="_blank"
                        > Blueberry Cake</Link> /
                        <Link
                            to="https://www.zomato.com/surat/the-centre-court-city-light/order"
                            target="_blank"
                        > Blueberry Cake</Link>
                    </div>
                </div>
            </div>
        </div>
    </>;
};

export default Flavours;
