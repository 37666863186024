
import React, { useState } from 'react';
import {
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
} from 'reactstrap';
import "../styles/Footer.css";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";
import logo1 from "../assets/logo/logoDesktop.png";
import { FaInstagram } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";

const Footer = () => {

    const [open, setOpen] = useState('');
    const toggle = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    };

    return <>
        <div className="footer d-none d-lg-block d-md-block">
            <div className='container px-5'>
                <div className="row">
                   
                    <div className="col-lg-4 col-md-5 footer-title">
                        <Link to="/">
                            <img src={logo1} alt="Footer Logo" className="footer-logo" />
                        </Link>
                        <p className="footer-address">Near Ambikaniketan Bus- Stand,<br />
                            Parle Point, Athwalines,<br />
                            Surat, Gujarat, India.
                        </p>
                        <p className="footer-contact">
                            <Link to="">
                                Phone: +91 88004 41320
                            </Link>
                        </p>
                        <div className="footer-social-media">
                            <span>Follow Us On:</span>
                            <ul className='social-icon'>
                                <li className=''>
                                    <Link to="https://www.facebook.com/thecentrecourtcakes" target='_blank' className="">
                                        <FaFacebookF />
                                    </Link>
                                </li>
                                <li className=''>
                                    <Link to="https://www.instagram.com/thecentrecourt_bakery" target='_blank' className="">
                                        <FaInstagram />
                                    </Link>
                                </li>

                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-3 footer-title">
                        <p>
                            More Info
                        </p>
                        <ul className="footer-list">
                            <li className="">
                                <Link to="/about-us" className="d-flex gap-2 align-items-center">
                                    <IoIosArrowForward />
                                    About Us
                                </Link>
                            </li>
                            <li className="">
                                <Link to="/contact-us" className="d-flex gap-2 align-items-center">
                                    <IoIosArrowForward />
                                    Contact Us
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col-lg-2 col-md-4 footer-title">
                        <p>
                            Popular Categories
                        </p>
                        <ul className="footer-list">
                            <li className="">
                                <Link to="/image-cakes" className="d-flex gap-2 align-items-center">
                                    <IoIosArrowForward />
                                    Image Cakes
                                </Link>
                            </li>
                            <li className="">
                                <Link to="/heart-cakes" className="d-flex gap-2 align-items-center">
                                    <IoIosArrowForward />
                                    Heart Cakes
                                </Link>
                            </li>
                            <li className="">
                                <Link to="/signature-cakes" className="d-flex gap-2 align-items-center">
                                    <IoIosArrowForward />
                                    Signature Cakes
                                </Link>
                            </li>
                            <li className="">
                                <Link to="/special-cakes" className="d-flex gap-2 align-items-center">
                                    <IoIosArrowForward />
                                    Special Cakes
                                </Link>
                            </li>
                            <li className="">
                                <Link to="/anniversary-cakes" className="d-flex gap-2 align-items-center">
                                    <IoIosArrowForward />
                                    Anniversary Cakes
                                </Link>
                            </li>
                            <li className="">
                                <Link to="all-cakes" className="d-flex gap-2 align-items-center">
                                    <IoIosArrowForward />
                                    All Cakes
                                </Link>
                            </li>
                        </ul>
                    </div>
                    {/* <div className='col-lg-5'>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7440.68001991871!2d72.79019648854843!3d21.178647926266997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04e63a5de0acb%3A0x1d999b73597c2abd!2sThe%20Centre%20Court!5e0!3m2!1sen!2sin!4v1737019858515!5m2!1sen!2sin" width="450" height="250" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div> */}
                    {/* <div className="col-lg-2 col-md-6 footer-title">
                        <p>
                            Our Policies
                        </p>
                        <ul className="footer-list">
                            <li className="">
                                <Link to="/privacy-policies" className="d-flex gap-2 align-items-center">
                                    <IoIosArrowForward />
                                    Privacy Policies
                                </Link>
                            </li>
                            <li className="">
                                <Link to="/term-conditions" className="d-flex gap-2 align-items-center">
                                    <IoIosArrowForward />
                                    Term & Conditions
                                </Link>
                            </li>
                            <li className="">
                                <Link to="/refund-cancellation" className="d-flex gap-2 align-items-center">
                                    <IoIosArrowForward />
                                    Refund & Cancellation
                                </Link>
                            </li>
                            <li className="">
                                <Link to="/shipping-policies" className="d-flex gap-2 align-items-center">
                                    <IoIosArrowForward />
                                    Shipping Policy
                                </Link>
                            </li>
                        </ul>
                    </div> */}
                    {/* <div className="col-lg-3 col-12 footer-title">
                        <p>
                            Subscription Offer
                        </p>
                        <div>

                        </div>
                    </div> */}
                </div>
                <hr className="footer-hr" />
                <div className="row">
                    <div className="col-lg-12">
                        <p className="footer-copyright">
                            © 2025 The Centre Court Cakes | Developed by
                            <Link to="https://www.setblue.com" target="_blank">
                                Setblue
                            </Link>
                        </p>
                    </div>
                </div>
            </div>
        </div>

        {/* Footer Mobile */}
        <div className="footer-mobile d-block d-lg-none d-md-none">
            <div className='container'>
                <div>
                    <Link to="/">
                        <img src={logo1} alt="Footer Logo" className="footer-logo" />
                    </Link>
                    <p className="footer-address">Near Ambikaniketan Bus- Stand,<br />
                        Parle Point, Athwalines,<br />
                        Surat, Gujarat, India.
                    </p>
                    <p className="footer-contact">
                        <Link to="">
                            Phone: +91 88004 41320
                        </Link>
                    </p>
                    <div className="footer-social-media">
                        <span>Follow Us On:</span>
                        <ul className='social-icon'>
                            <li className=''>
                                <Link to="#" className="">
                                    <FaFacebookF />
                                </Link>
                            </li>
                            <li className=''>
                                <Link to="#" className="">
                                    <FaInstagram />
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>

                <div>
                    <Accordion open={open} toggle={toggle}>
                        <AccordionItem>
                            <AccordionHeader targetId="1">
                                <div className="d-flex justify-content-between w-100">
                                    <span>More Info</span>
                                    <i class={`fas fa-chevron-down ms-1 
                                ${open === "1" ? "rotate-icon" : "reset-icon"
                                        }`}></i>
                                </div>
                            </AccordionHeader>
                            <AccordionBody accordionId="1">
                                <ul className="footer-list mb-0">
                                    <li className="">
                                        <Link to="/about-us" className="d-flex gap-2 align-items-center">
                                            <IoIosArrowForward />
                                            About Us
                                        </Link>
                                    </li>
                                    <li className="">
                                        <Link to="/contact-us" className="d-flex gap-2 align-items-center">
                                            <IoIosArrowForward />
                                            Contact Us
                                        </Link>
                                    </li>

                                </ul>
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionHeader targetId="2">
                                <div className="d-flex justify-content-between w-100">
                                    <span>Popular Categories</span>
                                    <i class={`fas fa-chevron-down ms-1 
                                ${open === "2" ? "rotate-icon" : "reset-icon"
                                        }`}></i>
                                </div>
                            </AccordionHeader>
                            <AccordionBody accordionId="2">
                                <ul className="footer-list">
                                    <li className="">
                                        <Link to="/image-cakes" className="d-flex gap-2 align-items-center">
                                            <IoIosArrowForward />
                                            Image Cakes
                                        </Link>
                                    </li>
                                    <li className="">
                                        <Link to="/heart-cakes" className="d-flex gap-2 align-items-center">
                                            <IoIosArrowForward />
                                            Heart Cakes
                                        </Link>
                                    </li>
                                    <li className="">
                                        <Link to="/signature-cakes" className="d-flex gap-2 align-items-center">
                                            <IoIosArrowForward />
                                            Signature Cakes
                                        </Link>
                                    </li>
                                    <li className="">
                                        <Link to="/special-cakes" className="d-flex gap-2 align-items-center">
                                            <IoIosArrowForward />
                                            Special Cakes
                                        </Link>
                                    </li>
                                    <li className="">
                                        <Link to="/anniversary-cakes" className="d-flex gap-2 align-items-center">
                                            <IoIosArrowForward />
                                            Anniversary Cakes
                                        </Link>
                                    </li>
                                    <li className="">
                                        <Link to="all-cakes" className="d-flex gap-2 align-items-center">
                                            <IoIosArrowForward />
                                            All Cakes
                                        </Link>
                                    </li>
                                </ul>
                            </AccordionBody>
                        </AccordionItem>
                    </Accordion>
                </div>
                <hr className="footer-hr" />
                <div className="row">
                    <div className="col-lg-12">
                        <p className="footer-copyright">
                            © 2025 The Centre Court Cakes | Developed by
                            <Link to="https://www.setblue.com" target="_blank">
                                Setblue
                            </Link>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </>;
};

export default Footer;
