import React from "react";
import img1 from "../assets/icons/icon01.png"
import img2 from "../assets/icons/icon02.png"
import img3 from "../assets/icons/icon03.png"
import img4 from "../assets/icons/icon04.png"
import img5 from "../assets/icons/icon05.png"
import img6 from "../assets/icons/icon06.png"

const Amenties = () => {
    return <>
        <div className="container ">
            <div className="section amenties-content">
                <h1 className="title">
                    Amenties
                </h1>
                <div className="row icon-row text-center">
                    <div className="col-lg-2 col-md-6 col-4">
                        <img src={img1} alt="amenties1" />
                        <p>Coffee Bar</p>
                    </div>
                    <div className="col-lg-2 col-md-6 col-4">
                        <img src={img2} alt="amenties1" />
                        <p>Cafeteria</p>
                    </div>
                    <div className="col-lg-2 col-md-6 col-4">
                        <img src={img3} alt="amenties1" />
                        <p>Ice Cream</p>
                    </div>
                    <div className="col-lg-2 col-md-6 col-4">
                        <img src={img4} alt="amenties1" />
                        <p>Pastries</p>
                    </div>
                    <div className="col-lg-2 col-md-6 col-4">
                        <img src={img5} alt="amenties1" />
                        <p>Cakes</p>
                    </div>
                    <div className="col-lg-2 col-md-6 col-4">
                        <img src={img6} alt="amenties1" />
                        <p>Meeting Area</p>
                    </div>
                </div>
</div>
            </div>
        </>;
};

        export default Amenties;
