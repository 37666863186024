import React from "react";
import cake1 from "../assets/cakes/cake1.png";
import cake2 from "../assets/cakes/cake5.jpeg";
import cake3 from "../assets/cakes/cake7.jpeg";
import nonveg from "../assets/image/nonveg.png";
import cloud from "../assets/image/cloud2.png";
import { Link } from "react-router-dom";


const ImageCakesPage = () => {

    const data = [
        {
            id: 1,
            image: cake1,
            title: "Edible Image Cake 001",
            time: "Min <br /> 5 Hr."
        },
        {
            id: 2,
            image: cake3,
            title: "Edible Photo Cake",
            time: "Min <br /> 5 Hr."
        },
        {
            id: 3,
            image: cake2,
            title: "Valentine Cupid Cake",
            time: "Min <br /> 5 Hr."
        },

    ]

    return (
        <>
            <div className="page-banner">
                <h1 className="breadcrumb-title">Edible Image Cakes</h1>
            </div>

            <div className="container">
                <div className="section mx-lg-5 mx-2">
                    <div className="row">
                        {
                            data.map((item) => (
                                <div className="col-lg-3 col-md-4 col-6 mb-3">
                                    <Link to="https://www.zomato.com/surat/the-centre-court-city-light/order"
                                        target="_blank" className="text-decoration-none">
                                        <div className="image-cakes" key={item.id}>
                                            <img src={item.image} alt="Cake" className="main-img img-fluid " />
                                            <div className="img-cakes-text d-flex justify-content-between align-items-center mt-3 pb-2 ">
                                                <p className="mb-0">{item.title}</p>
                                                <img src={nonveg} alt="Non-Veg Icon" className="nonveg-img img-fluid d-lg-block d-md-block d-none" style={{ width: '40px', height: '20px' }} />
                                            </div>
                                            <div className="cloud-container">
                                                <img src={cloud} alt="Cloud Decoration" className="cloud-img " />
                                                <small dangerouslySetInnerHTML={{ __html: item.time }}></small>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default ImageCakesPage;
