import React from "react";
import img1 from "../assets/image/aboutUs1.jpg";
import img2 from "../assets/image/aboutUs2.jpg";

const AboutUs = () => {
    return <>
        <div className="page-banner">
            <h1 className="breadcrumb-title">About Us</h1>
        </div>
        <div className="container-fluid section">
            <div className="row my-4">
                <div className="col-lg-6">
                    <img src={img1} alt="About Us" className="img-fluid" />
                </div>
                <div className="col-lg-6 d-table">
                    <div className="about-txt d-table-cell align-middle ">
                        <h2>A Little About Us</h2>
                        <p>The Centre Court was started in 1998 to craft the finest of foods using only fresh and natural ingredients. There is only one constant at The Centre Court which is "Quality"?, cutting all the other noises out.
                        </p>
                        <p>
                            It has all been, about a drive by Samir & Kavit Modi to bring the highest standards of creating the finest foods.
                        </p>
                        <p>
                            Do enjoy our cuisine, we hope you get addicted and keep coming back.
                        </p>
                        <p>
                            Our mission is to create the world's most exquisite cakes. Our inspiration comes from everywhere: art, fashion, fabric, furniture, architecture, landscapes, science, music, and history.
                        </p>
                    </div>
                </div>
            </div>
            <div className="row my-4 buddy-section">
                <h2>Meet Buddy</h2>
                <div className="col-lg-6 mt-4 d-table">
                    <div className="buddies d-table-cell align-middle">
                        <h3>Samir Modi</h3>
                        <p>Samir Modi drives the center court's strategic vision while overseeing the production of company products, ensuring all the products leaving the kitchen are of highest standard.</p>
                        <h3>Kavit Modi</h3>
                        <p>Kavit Modi leads the daily operations. He comes our as a person who engages himself with the customers and ensures they receive immaculate service.</p>
                    </div>
                </div>
                <div className="col-lg-6 mt-4">
                    <img src={img2} alt="About Us" className="img-fluid" />
                </div>
            </div>
        </div>
    </>;
};

export default AboutUs;
