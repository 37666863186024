import React from "react";
import Amenties from "../homeComponents/Amenties";
import Banner from "../homeComponents/Banner";
import ImageCakes from "../homeComponents/ImageCakes";
import HeartCakes from "../homeComponents/HeartCakes";
import SignatureCakes from "../homeComponents/SignatureCakes";
import SpecialCakes from "../homeComponents/SpecialCakes";
import CakeShowCase from "../homeComponents/CakeShowCase";
import Flavours from "../homeComponents/Flavours";
import Gallery from "../homeComponents/Gallery";

const Home = () => {
  return <>
    <Banner />
    <Amenties />
    <ImageCakes />
    <HeartCakes />
    <SignatureCakes />
    <SpecialCakes />
    <CakeShowCase />
    <Gallery />
    <Flavours />
  </>;
};

export default Home;
