import React, { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import "../styles/Banner.css";
import BannerMobile4 from "../assets/banner/BannerMobile4.jpg";
import BannerMobile3 from "../assets/banner/BannerMobile3.jpg";
import BannerMobile2 from "../assets/banner/BannerMobile2.jpg";
import BannerMobile1 from "../assets/banner/BannerMobile1.jpg";
import Banner1 from "../assets/banner/banner1.jpeg";
import Banner2 from "../assets/banner/banner2.jpeg";
import Banner3 from "../assets/banner/banner3.jpeg";
import Banner4 from "../assets/banner/banner4.jpeg";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";

const Banner = () => {
    const ArrowPrev = ({ onClick }) => (
        <div className="custom-arrow prev" onClick={onClick}>
            <IoIosArrowBack />
        </div>
    );

    const ArrowNext = ({ onClick }) => (
        <div className="custom-arrow next" onClick={onClick}>
            <IoIosArrowForward />
        </div>
    );

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <>
          
            <div className="carousel-container">
                <Carousel
                    autoPlay
                    showStatus={true}
                    infiniteLoop
                    showIndicators={false}
                    emulateTouch={false}
                    showThumbs={false}
                    renderArrowPrev={(onClickHandler, hasPrev, label) => (
                        <ArrowPrev onClick={onClickHandler} />
                    )}
                    renderArrowNext={(onClickHandler, hasNext, label) => (
                        <ArrowNext onClick={onClickHandler} />
                    )}
                >
                    <div>
                        <img src={isMobile ? BannerMobile1 : Banner1} alt="Carousel Slide 1" />
                    </div>
                    <div>
                        <img src={isMobile ? BannerMobile2 : Banner2} alt="Carousel Slide 2" />
                    </div>
                    <div>
                        <img src={isMobile ? BannerMobile3 : Banner3} alt="Carousel Slide 3" />
                    </div>
                    <div>
                        <img src={isMobile ? BannerMobile4 : Banner4} alt="Carousel Slide 4" />
                    </div>
                </Carousel>
            </div>
        </>
    );
};

export default Banner;
