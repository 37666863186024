// import React from "react";

// import gallery1 from "../assets/gallery/gallery1.webp";
// import gallery2 from "../assets/gallery/gallery2.webp";
// import gallery3 from "../assets/gallery/gallery3.webp";
// import gallery4 from "../assets/gallery/gallery4.webp";
// import gallery5 from "../assets/gallery/gallery5.webp";
// import gallery6 from "../assets/gallery/gallery6.webp";
// import gallery7 from "../assets/gallery/gallery7.webp";
// import gallery8 from "../assets/gallery/gallery8.webp";
// import gallery9 from "../assets/gallery/gallery9.webp";
// import gallery10 from "../assets/gallery/gallery10.webp";
// import gallery11 from "../assets/gallery/gallery11.webp";
// import gallery12 from "../assets/gallery/gallery12.webp";
// import gallery13 from "../assets/gallery/gallery13.webp";
// import gallery14 from "../assets/gallery/gallery14.webp";
// import gallery15 from "../assets/gallery/gallery15.webp";
// import gallery16 from "../assets/gallery/gallery16.webp";
// import gallery17 from "../assets/gallery/gallery17.webp";
// import gallery18 from "../assets/gallery/gallery18.webp";
// import gallery19 from "../assets/gallery/gallery19.webp";
// import gallery20 from "../assets/gallery/gallery20.webp";
// import gallery21 from "../assets/gallery/gallery21.webp";
// import gallery22 from "../assets/gallery/gallery22.webp";

// import Fancybox from "./FancyBox";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

// const Gallery = () => {

//     const settings = {
//         dots: false,
//         arrows: false,
//         infinite: false,
//         autoplay: false,
//         autoplaySpeed: 2000,
//         slidesToShow: 5,
//         slidesToScroll: 5,
//         responsive: [
//             {
//                 breakpoint: 1024,
//                 settings: {
//                     slidesToShow: 5,
//                     slidesToScroll: 5,
//                 },
//             },
//             {
//                 breakpoint: 768,
//                 settings: {
//                     slidesToShow: 3,
//                     slidesToScroll: 3,
//                 },
//             },
//             {
//                 breakpoint: 425,
//                 settings: {
//                     slidesToShow: 2,
//                     slidesToScroll: 2,
//                 },
//             },
//         ],
//     };

//     const data = [
//         { id: 1, image: gallery1 },
//         { id: 2, image: gallery2 },
//         { id: 3, image: gallery3 },
//         { id: 4, image: gallery4 },
//         { id: 5, image: gallery5 },
//         { id: 6, image: gallery6 },
//         { id: 7, image: gallery7 },
//         { id: 8, image: gallery8 },
//         { id: 9, image: gallery9 },
//         { id: 10, image: gallery10 },
//         { id: 11, image: gallery11 },
//         { id: 12, image: gallery12 },
//         { id: 13, image: gallery13 },
//         { id: 14, image: gallery14 },
//         { id: 15, image: gallery15 },
//         { id: 16, image: gallery16 },
//         { id: 17, image: gallery17 },
//         { id: 18, image: gallery18 },
//         { id: 19, image: gallery19 },
//         { id: 20, image: gallery20 },
//         { id: 21, image: gallery21 },
//         { id: 22, image: gallery22 },
//     ];

//     const preventDrag = (e) => {
//         e.preventDefault();
//     };

//     return (
//         <div className="section">
//             <h1 className="title">Gallery</h1>
//             <div className="container">
//                 <div className="carousel-container">
//                     <Slider {...settings}>
//                         {data.map((item) => (
//                             <Fancybox key={item.id}>
//                                 <div className="carousel" style={{ margin: "0 10px" }}>
//                                     <a
//                                         data-fancybox="gallery1"
//                                         href={item.image}
//                                         className="fancybox-link"
//                                         onMouseDown={preventDrag} 
//                                     >
//                                         <img src={item.image} alt={`Gallery ${item.id}`} className="gallery-img" />
//                                     </a>
//                                 </div>
//                             </Fancybox>
//                         ))}
//                     </Slider>
//                 </div>

//             </div>
//         </div>
//     );
// };

// export default Gallery;




import React from "react";

import gallery1 from "../assets/gallery/gallery1.webp";
import gallery2 from "../assets/gallery/gallery2.webp";
import gallery3 from "../assets/gallery/gallery3.webp";
import gallery4 from "../assets/gallery/gallery4.webp";
import gallery5 from "../assets/gallery/gallery5.webp";
import gallery6 from "../assets/gallery/gallery6.webp";

import Fancybox from "../components/FancyBox";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Gallery = () => {

    const settings = {
        dots: false,
        arrows: false,
        infinite: false,
        autoplay: false,
        autoplaySpeed: 2000,
        slidesToShow: 5,
        slidesToScroll: 5,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 425,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
        ],
    };

    const data = [
        { id: 1, image: gallery1 },
        { id: 2, image: gallery2 },
        { id: 3, image: gallery3 },
        { id: 4, image: gallery4 },
        { id: 5, image: gallery5 },
        { id: 6, image: gallery6 },
        
    ];

    const preventDrag = (e) => {
        e.preventDefault();
    };

    return (
        <div className="section">
            <h1 className="title">Gallery</h1>
            <div className="container">
                <div className="carousel-container">
                    <Slider {...settings}>
                        {data.map((item) => (
                            <Fancybox key={item.id}>
                                <div className="carousel" style={{ margin: "0 10px" }}>
                                    <a
                                        data-fancybox="gallery1"
                                        href={item.image}
                                        className="fancybox-link wave-hover"
                                        onMouseDown={preventDrag}
                                    >
                                        <img src={item.image} alt={`Gallery ${item.id}`} className="gallery-img" />
                                    </a>
                                </div>
                            </Fancybox>
                        ))}
                    </Slider>
                </div>

            </div>
        </div>
    );
};

export default Gallery;
